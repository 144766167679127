import { REQUEST_TYPES } from '@dreamcommerce/star_core';
import { TranslationsHttpApiUrls } from '@storefrontFeatures/translations/httpApi /translations_http_api_urls';
import { BaseHttpApi } from '@storefrontApp/base_http_api/base_http_api';

export class TranslationsHttpApi extends BaseHttpApi {
    private translationsHttpApiUrls = new TranslationsHttpApiUrls();

    async getTranslations() {
        const $link = document.querySelector<HTMLLinkElement>('#app-translations');

        if ($link) {
            try {
                return this.fetch<Record<string, string>>({
                    url: $link.href,
                    method: REQUEST_TYPES.get
                });
            } catch (error) {
                return null;
            }
        }
    }
}
