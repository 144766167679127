import {
    AsyncFeatureInitializer,
    FEATURE_CORES_TYPES,
    HTTP_REQUESTER_API_NAME,
    HTTPRequesterApi,
    TFeatureRecord
} from '@dreamcommerce/star_core';
import { TranslationsService } from '@storefrontFeatures/translations/state/services/translations_service';
import { TRANSLATION_NAMES } from '@storefrontFeatures/translations/translations_initializer_constants';
import { TranslationsApi } from '@storefrontFeatures/translations/api/translations_api';
import { TranslationsHttpApi } from '@storefrontFeatures/translations/httpApi /translations_http_api';
import { TranslationsI18nClient } from '@storefrontFeatures/translations/state/clients/translations_i18n_client';
import { StorefrontSettingsApi } from '@storefrontFeatures/storefront_settings/api/storefront_settings_api';
import { STOREFRONT_SETTINGS_NAMES } from '@storefrontFeatures/storefront_settings/storefront_settings_constants';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';

/* ATTENTION! If you have problems with Translations during tests, go to knowledge_base documentation */
export class TranslationsInitializer extends AsyncFeatureInitializer {
    public static featureName = TRANSLATION_NAMES.feature;

    async init(): Promise<TFeatureRecord> {
        const storefrontSettingsApi = await this.getApi<StorefrontSettingsApi>(STOREFRONT_SETTINGS_NAMES.api);
        const httpApi = this.getApiSync<HTTPRequesterApi>(HTTP_REQUESTER_API_NAME);

        const translationsHttpApi = new TranslationsHttpApi(httpApi);
        const translationClient = new TranslationsI18nClient(translationsHttpApi);
        const translationService = new TranslationsService(translationClient);

        await translationService.createInstance({ lang: storefrontSettingsApi.getLocales().locale });

        storefrontSettingsApi.selectSkinSettings$().subscribe((skinSettings) => {
            const { locale } = skinSettings ?? {};

            if (!!locale && locale.includes('_') && locale !== translationService.selectedLanguage()) {
                translationService.changeLanguage(skinSettings.locale as TLocaleIsoUnderscore);
            }
        });

        return {
            cores: [
                {
                    type: FEATURE_CORES_TYPES.api,
                    instance: new TranslationsApi(translationService)
                }
            ]
        };
    }
}
