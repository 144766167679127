import { TRANSLATION_NAMES } from '@storefrontFeatures/translations/translations_initializer_constants';
import {
    ITranslationsClient,
    TAddTranslationsOptionsParam,
    TCreateInstance,
    TTranslationOptions
} from '@storefrontFeatures/translations/translations_types';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';
import { TranslationKeys } from '@storefrontFeatures/translations/translation_keys_types';

export class TranslationsService implements ITranslationsClient {
    public moduleName = TRANSLATION_NAMES.service;

    constructor(private client: ITranslationsClient) {}

    public addTranslations(lang: TLocaleIsoUnderscore, translations: Record<string, string>, options?: TAddTranslationsOptionsParam): void {
        this.client.addTranslations(lang, translations, options);
    }

    public createInstance(instanceConfig: TCreateInstance): Promise<void> {
        return this.client.createInstance(instanceConfig);
    }

    public changeLanguage(lang: TLocaleIsoUnderscore): Promise<void> {
        return this.client.changeLanguage(lang);
    }

    public selectedLanguage(): TLocaleIsoUnderscore {
        return this.client.selectedLanguage();
    }

    public subscribeOnLanguageChange(callback: () => void) {
        return this.client.subscribeOnLanguageChange(callback);
    }

    public translate(key: TranslationKeys, options?: TTranslationOptions): string {
        return this.client.translate(key, options);
    }
}
