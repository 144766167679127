import i18next, { i18n } from 'i18next';
import { TranslationKeys } from '@storefrontFeatures/translations/translation_keys_types';
import { TInitI18NArguments } from '@storefrontFeatures/translations/state/services/translations_service_types';
import { CONSTANTS_I18N } from '@storefrontFeatures/translations/translations_initializer_constants';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';
import { TranslationsHttpApi } from '@storefrontFeatures/translations/httpApi /translations_http_api';
import { ITranslationsClient, TAddTranslationsOptionsParam, TTranslationOptions } from '@storefrontFeatures/translations/translations_types';

export class TranslationsI18nClient implements ITranslationsClient {
    private readonly TRANSLATION_BUNDLE_NAMESPACE = 'translation';
    private declare i18nInstance: i18n;

    constructor(private _translationsHttpApi: TranslationsHttpApi) {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    private async _getLangTranslationResource(): Promise<Record<string, string> | {}> {
        try {
            const request = await this._translationsHttpApi.getTranslations();

            if (!request) {
                return {};
            }

            const { data } = await request.response;

            return data;
        } catch (err) {
            console.error(err);
            return {};
        }
    }

    public async createInstance({ fallbackLang, lang }: TInitI18NArguments) {
        if (this.i18nInstance) {
            return;
        }

        const translationsResource = await this._getLangTranslationResource();

        this.i18nInstance = i18next.createInstance();
        await this.i18nInstance.init(
            {
                debug: false,
                fallbackLng: fallbackLang,
                keySeparator: false,
                nsSeparator: ';',
                lng: lang,
                resources: {
                    [lang]: {
                        [this.TRANSLATION_BUNDLE_NAMESPACE]: translationsResource
                    }
                }
            },
            (err) => this._afterInit(err, fallbackLang)
        );
    }

    private _afterInit(err: unknown, fallbackLang?: TLocaleIsoUnderscore) {
        if (err) {
            return;
        }

        this._resolveFallbackLanguage(fallbackLang);
        this._missingKey();
    }

    private async _resolveFallbackLanguage(fallbackLang?: TLocaleIsoUnderscore) {
        if (!fallbackLang) {
            return;
        }

        const fallbackTranslationsResource = await this._getLangTranslationResource();

        this.addTranslations(fallbackLang, fallbackTranslationsResource);
    }

    private _missingKey() {
        if (process.env.NODE_ENV !== 'production') {
            this.i18nInstance.on('missingKey', (lngs, namespace, key, res) => {
                console.group('MissingTranslation');
                console.log('Missing Translation Details');
                console.log({
                    lngs,
                    namespace,
                    key,
                    res
                });
                console.groupEnd();
                throw new Error('TRANSLATION KEY MISSING!');
            });
        }
    }

    public async changeLanguage(lang: TLocaleIsoUnderscore) {
        if (!this.i18nInstance.hasResourceBundle(lang, this.TRANSLATION_BUNDLE_NAMESPACE)) {
            const missingTranslationsResource = await this._getLangTranslationResource();

            this.addTranslations(lang, missingTranslationsResource);
        }

        await this.i18nInstance.changeLanguage(lang);

        return;
    }

    public translate(key: TranslationKeys, options?: TTranslationOptions): string {
        if (!this.i18nInstance) {
            console.warn('Translation requested before proper initialization');

            return '';
        }

        return this.i18nInstance.t(key, options);
    }

    public subscribeOnLanguageChange(callback: () => void) {
        this.i18nInstance.on(CONSTANTS_I18N.LANGUAGE_CHANGED, callback);

        return () => this.i18nInstance.off(CONSTANTS_I18N.LANGUAGE_CHANGED, callback);
    }

    public addTranslations(lang: TLocaleIsoUnderscore, translations: Record<string, string>, options?: TAddTranslationsOptionsParam) {
        // eslint-disable-next-line prettier/prettier
        const { bundle = this.TRANSLATION_BUNDLE_NAMESPACE, deep = false, overwrite = false } = options ?? {};

        this.i18nInstance.addResourceBundle(lang, bundle, translations, deep, overwrite);
    }

    public selectedLanguage(): TLocaleIsoUnderscore {
        return this.i18nInstance.language as TLocaleIsoUnderscore;
    }
}
