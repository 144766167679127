const featureName = 'StorefrontSettings';
export const STOREFRONT_SETTINGS_NAMES = {
    feature: featureName,
    component: '',
    api: `${featureName}Api`,
    service: `${featureName}Service`,
    store: `${featureName}Store`,
    countriesHttpApi: `${featureName}CountriesHttpApi`
} as const;

export const SKIN_SETTINGS_KEY = 'skinSettings';
export const PAGE_SETTINGS_KEY = 'pageSettings';
export const INPOST_PAY_WIDGET_SETTINGS_KEY = 'inpostPayWidgetSettings';
export const P24_INSTALLMENTS_SETTINGS_KEY = 'p24InstallmentsSettings';
export const HTTP_API_PREFIX = 'webapi/front';
