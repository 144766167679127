import { FeatureApi } from '@dreamcommerce/star_core';
import { TranslationKeys } from '@storefrontFeatures/translations/translation_keys_types';
import { TRANSLATION_NAMES } from '@storefrontFeatures/translations/translations_initializer_constants';
import { ITranslationApi, ITranslationsClient, TTranslationOptions } from '@storefrontFeatures/translations/translations_types';
import { TLocaleIsoUnderscore } from '@dreamcommerce/star_core/build/esm/packages/star_core/src/classes/locale/locale_types';

export class TranslationsApi extends FeatureApi implements ITranslationApi {
    readonly moduleName = TRANSLATION_NAMES.api;

    constructor(private _translationsService: ITranslationsClient) {
        super();
    }

    public selectedLanguage() {
        return this._translationsService.selectedLanguage();
    }

    public changeLanguage(lang: TLocaleIsoUnderscore) {
        return this._translationsService.changeLanguage(lang);
    }

    public translate(key: TranslationKeys, options?: TTranslationOptions) {
        return this._translationsService.translate(key, options);
    }

    public subscribeOnLanguageChange(callback: () => void) {
        return this._translationsService.subscribeOnLanguageChange(callback);
    }
}
